._2oSbenaQCQZPXOD75Sj6nE {
  overflow: hidden;
  position: relative;
  border: 1px solid #00B2CA;
  margin: 0 auto; }

._2D3XigQoPKPCPCHAaOtqJP {
  border-radius: 50%; }

._3m334JNOLa1lrKCCrXnNDG {
  border-radius: 4px; }

._2oSbenaQCQZPXOD75Sj6nE img {
  display: none;
  user-select: none;
  position: relative; }
._1Fq_tZ_l7SwH_r_a_SNOtA {
  position: relative;
  font-family: sans-serif;
  font-size: 85%; }

._1zaTZoyOruLA27LomLt6fx ._34KU8GIMgtEHCcQX2pjZJE, ._1KYh_YChgsCwB4c5gd4q1k ._34KU8GIMgtEHCcQX2pjZJE, ._1Sok0Akre9PW5C22TIkrKD ._34KU8GIMgtEHCcQX2pjZJE {
  cursor: pointer; }

._1PTKmYTPmkH41HyPQ6Yp1q ._34KU8GIMgtEHCcQX2pjZJE,
._1PTKmYTPmkH41HyPQ6Yp1q ._33ob_2Sxjrzz9VgWuEn9xb {
  cursor: move; }

._2Y6QsytFkIen8EZ8qRminO {
  display: none; }

._1Fq_tZ_l7SwH_r_a_SNOtA ._3pDZ7o2d3BEKT7GOFhs90M {
  text-align: center;
  margin-bottom: 15px auto; }

._1Fq_tZ_l7SwH_r_a_SNOtA ._1Ou1tAFc1VCD4TNa0XwE7z {
  position: relative;
  background-repeat: no-repeat;
  background-color: transparent;
  padding: 10% 0;
  user-select: none; }

._1Fq_tZ_l7SwH_r_a_SNOtA .GNSzzq7thIGJvQKQMnUF4 {
  height: 40px; }

._1Fq_tZ_l7SwH_r_a_SNOtA ._34iiqkc-jTKb3N7DvLLkwC {
  width: 85%;
  vertical-align: middle;
  display: inline-block; }

._1Fq_tZ_l7SwH_r_a_SNOtA ._10Kns8R3VdHSGFrESIz-4B {
  text-align: center;
  width: 15%;
  vertical-align: middle;
  display: inline-block; }

._1Fq_tZ_l7SwH_r_a_SNOtA ._1YelFC_KTV9aLijs9ogXcQ {
  background: transparent;
  border: 0;
  cursor: pointer; }

._1DghVSjROos1S_s0jPt9TQ {
  cursor: move; }

._1DghVSjROos1S_s0jPt9TQ, ._33ob_2Sxjrzz9VgWuEn9xb {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

._33ob_2Sxjrzz9VgWuEn9xb {
  width: 100%;
  height: 100%; }
.YpD9o6H9LydyYmaPWOwt {
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0;
  bottom: 0;
  color: #23395B;
  text-align: center;
  z-index: 3;
  user-select: none;
  background: #FFF; }

._6IMSWtd3wtGGn2KgOcZIK {
  position: absolute;
  left: 5px;
  right: 5px;
  top: 50%;
  transform: translateY(-50%); }

.fe6xZVPr1kb0CJksW39zV {
  padding: 0;
  font-size: 0.9em; }

.eXEoGG26iwSi6UiZD8FI6 {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  border: 0;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit; }
._1MKAF96uXJHSW-a1nWoriQ {
  position: relative;
  width: 90%;
  margin: 10px auto; }

.kWobJZ5IBLVJtFBD9JHAQ {
  -webkit-appearance: none;
  display: block;
  margin: 0 auto;
  width: calc(100% - 40px);
  height: 18px;
  background: transparent; }

.kWobJZ5IBLVJtFBD9JHAQ:focus::-ms-thumb {
  border-color: #23395B;
  box-shadow: 0 0 1px 0px #23395B; }

.kWobJZ5IBLVJtFBD9JHAQ::-webkit-slider-thumb {
  -webkit-appearance: none; }

.kWobJZ5IBLVJtFBD9JHAQ::-moz-range-thumb {
  border-color: #23395B;
  box-shadow: 0 0 1px 0px #23395B; }

.kWobJZ5IBLVJtFBD9JHAQ::-webkit-slider-thumb {
  border-color: #23395B;
  box-shadow: 0 0 1px 0px #23395B;
  -webkit-appearance: none;
  margin-top: -9px;
  box-sizing: border-box;
  cursor: pointer;
  width: 18px;
  height: 18px;
  display: block;
  border-radius: 50%;
  background: #eee;
  border: 1px solid #23395B; }

.kWobJZ5IBLVJtFBD9JHAQ::-webkit-slider-thumb:hover {
  border-color: #3f67a5; }

.kWobJZ5IBLVJtFBD9JHAQ::-ms-track {
  width: 100%;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent; }

.kWobJZ5IBLVJtFBD9JHAQ::-ms-thumb {
  margin-top: 0;
  box-sizing: border-box;
  cursor: pointer;
  width: 18px;
  height: 18px;
  display: block;
  border-radius: 50%;
  background: #eee;
  border: 1px solid #23395B; }

.kWobJZ5IBLVJtFBD9JHAQ::-ms-thumb:hover {
  border-color: #070b11; }

.kWobJZ5IBLVJtFBD9JHAQ::-moz-range-thumb {
  margin-top: 0;
  box-sizing: border-box;
  cursor: pointer;
  width: 18px;
  height: 18px;
  display: block;
  border-radius: 50%;
  background: #eee;
  border: 1px solid #23395B; }

.kWobJZ5IBLVJtFBD9JHAQ::-moz-range-thumb::-moz-range-thumb:hover {
  border-color: #070b11; }

.kWobJZ5IBLVJtFBD9JHAQ::-webkit-slider-runnable-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: #eee;
  border: 0; }

.kWobJZ5IBLVJtFBD9JHAQ::-moz-range-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: #eee;
  border: 0; }

.kWobJZ5IBLVJtFBD9JHAQ::-ms-track {
  width: 100%;
  height: 1px;
  cursor: pointer;
  background: #eee;
  border: 0; }

._23VzqtDiBsdmyddgDcP2FF, ._2JJI18xT4B0iIKyuZLMpk9 {
  position: absolute;
  transition: color 0.3s ease;
  fill: #23395B; }

._23VzqtDiBsdmyddgDcP2FF {
  left: -12px;
  top: 0px; }

._2JJI18xT4B0iIKyuZLMpk9 {
  right: -13px;
  top: -1px; }

._3L1oDMFmgTzTs8MCYm-pXt {
  fill: #a2b8db; }
